import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingButton } from "@mui/lab";
import { CircularProgress, TextField } from "@mui/material";
import config from '../../utils/config'

function validateTelephone(telephone) {
  const formatTelephone = /^(06|05|04)[0-9]{7}$/;
  return formatTelephone.test(telephone);
}

const Login = () => {
  const [telephone, setTelephone] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showBtn, setShowBtn] = useState(false);

  const [msgTelephone, setMsgTelephone] = useState("");
  const [msgPassword, setMsgPassword] = useState("");


  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = `${config.apiUrl}/comptes/users/login/`;

    const data = { username: telephone, password: password };
    try {
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("data =================> ", data)
          if (data.message) {
            setError(data.message);
          } else {
            if (
              Array.isArray(data.password) &&
              data.password[0] &&
              Array.isArray(data.telephone) &&
              data.telephone[0]
            ) {
              setError("Les champs ne doivent pas être vides");
            } else {
              localStorage.setItem("user", JSON.stringify(data));
              toast.success('Connexion réussie !', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                navigate(-1)
              
            }
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("Erreur => ", error)
          setError("Une erreur est survenue, veuillez réessayer plus tard")
          setIsLoading(false)
        });
    } catch (error) {
      console.log("Erreur : ", error);
    }
  };

  useEffect(() => {
    setError("");
    if (telephone !== "" && validateTelephone(telephone) && password !== '' && password.length >= 6 ) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
    // eslint-disable-next-line
  }, [telephone, password]);

  useEffect(() => {
    if(telephone === ''){
      setMsgTelephone("Le téléphone est obligatoire")
    }else{
      if(!validateTelephone(telephone)){
        setMsgTelephone("Le téléphone n'est pas valide")
      }else{
        setMsgTelephone('')
      }
    }
  }, [telephone])

  useEffect(() => {
    if(password === ''){
      setMsgPassword("Le mot de passe est obligatoire")
    }else{
      if(password.length < 6){
        setMsgPassword("Le mot de passe doit être supérieur à 6 caractères")
      }else{
      setMsgPassword('')
      }
    }
  }, [password])

  useEffect(() => {
    setMsgTelephone("");
    setMsgPassword("");
    setError("");
  }, []);

  return (
    <>
      <section className="signup-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="signup-form">
                <h2 style={{fontFamily: 'arial'}}>Content de vous revoir!</h2>
                <form>
                  <div className="row">
                    <TextField
                      type="text"
                      name="telephone"
                      label="Numéro de telephone"
                      value={telephone}
                      onChange={e => {
                        setTelephone(e.target.value)
                      }}
                      placeholder="Ex : 064723424"
                      variant="outlined"
                      required
                      error={Boolean(msgTelephone)}
                      helperText={msgTelephone}
                      style={{marginBottom: '20px'}}
                    />
                    <TextField
                        type="password"
                        name="password"
                        label="Mot de passe"
                        value={password}
                        onChange={e => {  
                          setPassword(e.target.value)
                        }}
                        placeholder="Mot de passe"
                        variant="outlined"
                        required
                        error={Boolean(msgPassword)}
                        helperText={msgPassword}
                        style={{marginBottom: '20px'}}
                      />
                    <span style={{color: 'red', textAlign: 'center'}}>{error}</span>
                    <div className="col-lg-12">
                      {
                        showBtn &&
                        <LoadingButton
                          loading={isLoading}
                          variant='contained'
                          sx={{backgroundColor: '#1d42d9', borderRadius: 50, height: 50, marginTop: 2}}
                          loadingIndicator={<CircularProgress color="inherit" size={30} />}
                          onClick={handleSubmit}
                        >
                          S'identifier
                        </LoadingButton>
                      }
                    </div>
                    <span className="already">
                      Nouveau compte{" "}
                      <Link to="/register" className="link ">
                        S'inscrire
                      </Link>
                    </span>
                    <span className="already">
                      Mot de passe oublié ? {" "}
                      <Link to="/forgotPassword" className="link ">
                        Changer le mot de passe
                      </Link>
                    </span>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="sign-up-img">
                <img src="assets/images/signup.svg" alt="singup" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
